<template>
  <div v-if="cycle">
    <div class="flex gap-2">
      <div v-if="cycle.weight_start_id">
        <div class="font-bold text-nowrap">{{ cycle.weight_start_value | round }} g</div>
      </div>

      <div>-</div>

      <div v-if="cycle.weight_end_id">
        <div class="font-bold text-nowrap">{{ cycle.weight_end_value | round }} g</div>
      </div>
      <div v-else>
        <b-btn
          variant="primary"
          size="xs"
          @click="stopBurning()"
          :disabled="sample.busy_progress"
        >
          <div class="flex gap-2 items-center">
             <b-spinner v-if="sample.busy_progress" small label="Loading..."></b-spinner>
            <div class="text-nowrap">End cycle</div>
          </div>
        </b-btn>
      </div>

      <div v-if="cycle.weight_start_id && cycle.burn_duration">
        <div class="text-nowrap">({{ formatDuration(cycle.burn_duration) }})</div>
      </div>
    </div>
  </div>
  <div v-else-if="isNextAvailable" class="text-nowrap">
    <b-btn
      variant="primary"
      size="xs"
      @click="startBurning()"
      :disabled="sample.busy_progress"
    >
      <div class="flex gap-2 items-center">
        <b-spinner v-if="sample.busy_progress" small label="Loading..."></b-spinner>
        <div class="text-nowrap">Start cycle</div>
      </div>
    </b-btn>
  </div>
  <div v-else>
    <!-- Unavailable -->
  </div>
</template>

<script>
import { formatDuration } from '@/helpers';

export default {
  name: 'SampleCycle',
  components: {},
  props: {
    sample: Object,
    cycles: Array,
    cycleNumber: Number,
  },
  computed: {
    prevCycle() {
      return this.cycles.find((cycle) => cycle.cycle_number === this.cycleNumber - 1);
    },
    cycle() {
      return this.cycles.find((cycle) => cycle.cycle_number === this.cycleNumber);
    },
    isLast() {
      return this.cycles.length === this.cycleNumber;
    },
    isNextAvailable() {
      if (this.cycles.length === 0) return this.cycleNumber === 1;
      const isNext = this.cycles[this.cycles.length - 1].cycle_number + 1 === this.cycleNumber;
      const isPrevFinished = this.prevCycle ? this.prevCycle.weight_end_id !== null : true;
      const isNextAvailable = isNext && isPrevFinished;
      return isNextAvailable;
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDuration(seconds) {
      return formatDuration(seconds);
    },
    startBurning() {
      this.$emit('startBurning', this.sample);
    },
    stopBurning() {
      this.$emit('stopBurning', this.sample);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
